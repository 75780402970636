<template>
  <div>
    <el-carousel v-if="!loading" height="90vh" trigger="click" :autoplay="true">
      <el-carousel-item v-for="(item, index) in images" :key="index">
        <div class="image-container">
          <!-- 在移动设备上显示标题 -->
          <div v-if="isMobile" class="carousel-title">5G智能记录仪</div>
          <img
              :src="isMobile ? mobileImageUrl : item.fullImageUrl"
              :alt="item.title"
              class="carousel-image"
          />
          <!-- 在移动设备上按钮移动到图片下方 -->
          <button @click="redirectToHardware" class="redirect-button">了解详情</button>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div v-else class="spinner-container">
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AppCarousel',
  data() {
    return {
      mobileImageUrl: require('@/assets/banner/banner_mobile.png'), // 引入移动设备图片
      isMobile: false,  // 用于判断是否是移动设备
    };
  },
  computed: {
    ...mapGetters(['images', 'loading'])
  },
  created() {
    if (this.images.length === 0) {
      this.fetchImages();
    }
    this.checkIsMobile(); // 初次加载时检查设备类型
    window.addEventListener('resize', this.checkIsMobile); // 监听窗口大小变化
  },
  beforeUnmount() { // 修改为beforeUnmount
    window.removeEventListener('resize', this.checkIsMobile); // 组件销毁前移除监听
  },
  methods: {
    ...mapActions(['fetchImages']),
    redirectToHardware() {
      window.location.href = '/hardware';
    },
    checkIsMobile() {
      this.isMobile = window.innerWidth <= 768;
    }
  }
};
</script>

<style scoped>
.image-container {
  position: relative; /* 使按钮能够覆盖在图片上 */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160vh; /* 图片宽度设置为160vh */
  height: 90vh; /* 图片高度设置为90vh */
  margin: 0 auto; /* 图片居中 */
  padding: 0;
}

.carousel-image {
  width: 100%; /* 图片宽度占满容器 */
  height: 100%; /* 高度占满容器 */
  object-fit: cover; /* 保持图片的宽高比并裁剪 */
  object-position: right; /* 图片显示右侧部分 */
}

.redirect-button {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: auto; /* 宽度自动调整 */
  max-width: 80%; /* 限制最大宽度 */
  padding: 10px 20px; /* 添加内边距 */
  background-color: rgb(248, 163, 4);
  color: white;
  border: none;
  font-size: 1.5vw; /* 使用相对单位 */
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  white-space: normal; /* 允许文字换行 */
  word-wrap: break-word; /* 自动换行 */
}

.redirect-button:hover {
  background-color: #0077cc;
  color: #fff;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  animation: spin 1s linear infinite;
}

/* 隐藏轮播图两侧的上一张/下一张按钮 */
.el-carousel__arrow--left, .el-carousel__arrow--right {
  display: none;
}

/* 仅在移动设备上应用样式 */
@media (max-width: 768px) {
  .image-container {
    width: calc(100vw - 160px); /* 添加左右各60px的留白 */
    height: auto; /* 高度自动适应 */
    flex-direction: column; /* 调整布局为纵向 */
    padding-bottom: 20px; /* 为按钮留出空间 */
  }

  .carousel-title {
    font-size: 8vw;
    color: #333;
    margin-bottom: 10px; /* 标题与图片之间的间距 */
    text-align: center; /* 标题居中显示 */
  }

  .carousel-image {
    width: 100%;
    height: auto;
    max-height: 75vh; /* 限制图片最大高度 */
    object-position: center;
  }

  .redirect-button {
    position: relative;
    bottom: auto;
    left: auto;
    transform: none;
    width: 80%;
    margin-top: 10px;
    font-size: 4vw;
  }
}
</style>
