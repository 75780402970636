import axios from 'axios';

// 创建一个axios实例
const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL, // 使用环境变量
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

export default {
    getBanners() {
        return apiClient.get('banner/');
    },
    getProducts(){
        return apiClient.get('product/');
    }
};
