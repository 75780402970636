<template>
  <div class="header-container">
    <el-row type="flex" align="middle">
      <!-- Logo -->
      <el-col :span="6" class="logo-container">
        <a href="/"><img src="@/assets/logo-513.png" alt="Logo" class="logo" /></a>
      </el-col>

      <!-- 汉堡菜单按钮 -->
      <el-col :span="6" class="burger-menu-container" @click="toggleMobileMenu">
        <el-icon>
          <Menu />
        </el-icon>
      </el-col>

      <el-col :span="12" class="nav-container">
        <ul class="nav-content">
          <li>
            <router-link :to="'/'" @click="selectNav('home')">
              <span class="header-label-wrap">首页</span>
            </router-link>
          </li>
          <li @mouseover="showDropdown" @mouseleave="startHideDropdownTimer" class="clickable">
            <span class="header-label-wrap">产品</span>
          </li>
          <li class="menu-link clickable" @click="redirectToChat">
            <span class="header-label-wrap">AdaChat</span>
          </li>
          <li>
            <router-link :to="'/contactus'" @click="selectNav('contactus')">
              <span class="header-label-wrap">联系我们</span>
            </router-link>
          </li>
        </ul>
      </el-col>

      <el-col :span="6" class="search-container" @mouseover="rotateSearchIcon(true)"
              @mouseleave="rotateSearchIcon(false)">
        <el-icon style="transition: transform 0.3s"
                 :style="{ transform: isSearchIconRotated ? 'rotate(90deg)' : 'rotate(0deg)' }">
          <Search />
        </el-icon>
      </el-col>
    </el-row>

    <div v-if="dropdownVisible" class="dropdown-wrapper" @mouseleave="startHideDropdownTimer">
      <div class="dropdown-content-wrapper" @mouseover="clearHideDropdownTimer">
        <div class="dropdown-content">
          <table class="submenu-table">
            <tr>
              <td class="non-selectable">产品</td>
              <td class="non-selectable">AI</td>
            </tr>
            <tr>
              <td @click="redirectToHardware">记录仪</td>
              <td @click="redirectToSmart">私有化部署</td>
            </tr>
            <tr>
              <td @click="redirectToSmart">智能眼镜</td>
              <td @click="redirectToChat">AdaChat</td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <!-- 移动端菜单 -->
    <div v-if="mobileMenuVisible" class="mobile-menu">
      <ul>
        <li @click="redirectToHome">首页</li>
        <li @click="redirectToHardware">记录仪</li>
        <li @click="redirectToSmart">智能眼镜</li>
        <li @click="redirectToSmart">私有化部署</li>
        <li @click="redirectToChat">AdaChat</li>
        <li @click="redirectToContactUs">联系我们</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Search, Menu } from '@element-plus/icons-vue';

export default {
  components: {
    Search,
    Menu,
  },
  data() {
    return {
      selectedNav: 'home',
      isScrolled: false,
      dropdownVisible: false,
      isSearchIconRotated: false,
      chatLink: process.env.VUE_APP_CHAT_LINK,
      hideDropdownTimer: null,
      mobileMenuVisible: false,
    };
  },
  methods: {
    selectNav(name) {
      this.selectedNav = name;
    },
    showDropdown() {
      this.dropdownVisible = true;
    },
    startHideDropdownTimer() {
      this.hideDropdownTimer = setTimeout(() => {
        this.dropdownVisible = false;
      }, 100);
    },
    clearHideDropdownTimer() {
      clearTimeout(this.hideDropdownTimer);
    },
    rotateSearchIcon(value) {
      this.isSearchIconRotated = value;
    },
    redirectToChat() {
      window.location.href = '/chat';
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },
    redirectToHardware() {
      window.location.href = '/hardware';
    },
    redirectToSmart() {
      window.location.href = '/smart';
    },
    redirectToHome() {
      window.location.href = '/';
    },
    redirectToContactUs() {
      window.location.href = '/contactus';
    },
    toggleMobileMenu() {
      this.mobileMenuVisible = !this.mobileMenuVisible;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style scoped>
.header-container {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
  position: relative; /* 确保 header 固定在页面顶部 */
  top: 0;
}

.logo-container {
  display: flex;
  justify-content: flex-end;
}

.logo {
  max-height: 40px;
  width: auto;
}

.nav-container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.nav-content {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
  line-height: 1.5;
}

.nav-content li {
  margin: 0 20px;
  position: relative;
}

.nav-content a,
.nav-content router-link,
.menu-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  font-size: 16px;
  font-weight: 500;
}

.header-label-wrap {
  padding: 10px 15px;
}

.dropdown-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.dropdown-content-wrapper {
  position: relative;
}

.dropdown-content {
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
  padding: 10px 0;
  box-sizing: border-box;
  width: 100%;
}

.submenu-table {
  margin: 0 auto;
  border-collapse: collapse;
}

.submenu-table td {
  padding: 5px 20px;
  text-align: left;
  cursor: pointer;
}

.submenu-table .non-selectable {
  cursor: default;
  font-weight: bold;
}

.search-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.clickable {
  cursor: pointer;
}

.burger-menu-container {
  display: none;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 0;
  height: 100%;  /* 确保高度充满父容器 */
}

.mobile-menu {
  display: none;
  position: absolute;
  top: 20px;
  right: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 1000;
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-menu li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: center;
  cursor: pointer;
}

.mobile-menu li a {
  text-decoration: none;
  color: #000;
}

/* Media query for responsive behavior */
@media (max-width: 768px) {
  .nav-container {
    display: none;
  }

  .burger-menu-container {
    display: flex;
  }

  .mobile-menu {
    display: block;
  }

  .dropdown-wrapper {
    position: static;
  }

  .logo-container {
    justify-content: center; /* 在移动设备上，Logo居中显示 */
  }

  .logo {
    max-height: 20px; /* 调整Logo的高度，以适应小屏幕 */
    width: auto;
  }

  .search-container {
    justify-content: flex-end; /* 在移动设备上靠右对齐 */
    position: absolute;
    right: 60px; /* 保证在汉堡菜单左侧 */
    top: 0;
    height: 100%;
  }
}
</style>
